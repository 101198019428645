<script setup lang="ts">
const { notifications, removeOne } = useNotifications();
</script>
<template>
  <div
    data-testid="notification-container"
    class="fixed right-5 bottom-10 sm:top-20 max-h-fit notifications-layout"
  >
    <LayoutNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @click:close="removeOne(notification.id)"
    />
  </div>
</template>
<style>
.notifications-layout {
  z-index: 50;
}
.right-5 {     right: 1.25rem /* 20px */; }
.bottom-10 {     bottom: 2.5rem /* 40px */; }
@media (min-width: 640px) {     .sm\:top-20 {         top: 5rem /* 80px */;     } }
</style>
